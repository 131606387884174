/*
 * @Date: 2023-11-20 16:58:16
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-10 09:44:13
 * @FilePath: \zyt-mobile-frontend\src\utils\util.js
 */
import { api } from "@/api/index"
import store from "@/store"
//获取图片地址
export function requestImgUrl(url) {
  return new Promise((resolve, reject) => {
    var urlArr = url.split("/")
    var fileName = urlArr[urlArr.length - 2] + "/" + urlArr[urlArr.length - 1]
    api
      .getNewImgUrl({
        fileName: fileName,
      })
      .then((res) => {
        resolve(res)
      })
  })
}

// 是否已选放映点
export function getUserPoint() {
  return store.state.userPoint
}
